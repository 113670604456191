
import { defineComponent, PropType } from '@nuxtjs/composition-api';
import type { ArticleEeatBlokInterface } from '~/types/articlePage';
import type { GlobalArticleCopy } from '~/types/content';

export default defineComponent({
    name: 'ArticleEeat',
    props: {
        blok: {
            type: Object as PropType<ArticleEeatBlokInterface>,
            required: true,
        },
        firstPublishedAt: {
            type: String,
            default: '',
        },
        publishedAt: {
            type: String,
            default: '',
        },
        seoData: {
            type: Object,
            required: true,
        },
        globalArticleCopy: {
            type: Object as PropType<GlobalArticleCopy>,
            required: true,
        },
    },
    computed: {
        hasArticleDate() {
            return !!this.blok.legacyUpdatedDate || !!this.firstPublishedAt || !!this.publishedAt;
        },
    },
});
