var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{directives:[{name:"editable",rawName:"v-editable",value:(_vm.blok),expression:"blok"}],staticClass:"mb-200 pt-100"},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('h1',[_vm._v("\n                "+_vm._s(_vm.blok.header)+"\n            ")])])],1),_vm._v(" "),_c('b-row',[_c('b-col',{attrs:{"cols":"12","lg":"6"}},[_c('p',{staticClass:"font-size-300 pt-0 mt-0 mb-50"},[_vm._v("\n                "+_vm._s(_vm.blok.subheader)+"\n            ")]),_vm._v(" "),_c('social-links',{staticClass:"mb-150",attrs:{"nuxt-route-path":_vm.$route.path,"unique-id":"eeat","share-text":_vm.blok.header,"share-category":_vm.blok.category,"seo-data":_vm.seoData,"copy-tooltip-success":_vm.globalArticleCopy.linkCopySuccess}})],1),_vm._v(" "),_c('b-col',{staticClass:"text-lg-right",attrs:{"cols":"12","lg":"6"}},[_c('div',{staticClass:"mb-50"},[_c('token-replace',{attrs:{"string":_vm.globalArticleCopy.writtenByAuthors},scopedSlots:_vm._u([{key:"authors",fn:function(){return [_c('ul',{staticClass:"comma-separated-list list-unstyled d-inline-flex m-0 p-0"},_vm._l((_vm.blok.authors),function(author){return _c('li',{key:author.id,staticClass:"d-flex"},[_c('storyblok-link',{attrs:{"link":{
                                        linktype: 'story',
                                        story: author,
                                    }}},[_vm._v("\n                                    "+_vm._s(author?.content?.authorInfo?.[0]?.name)+"\n                                ")])],1)}),0)]},proxy:true}])})],1),_vm._v(" "),(_vm.blok.editedBy)?_c('div',{staticClass:"mb-50"},[_c('token-replace',{attrs:{"string":_vm.globalArticleCopy.editedByAuthor},scopedSlots:_vm._u([{key:"editor",fn:function(){return [(_vm.blok?.editedBy?.content)?_c('storyblok-link',{attrs:{"link":{
                                linktype: 'story',
                                story: _vm.blok.editedBy,
                            }}},[_vm._v("\n                            "+_vm._s(_vm.blok.editedBy.content?.authorInfo?.[0]?.name)+"\n                        ")]):_vm._e()]},proxy:true}],null,false,2018726012)})],1):_vm._e(),_vm._v(" "),_c('div',{staticClass:"d-flex d-lg-inline-flex justify-space-between mb-0"},[(_vm.hasArticleDate)?_c('div',{staticClass:"mr-150"},[_c('article-update-date',{attrs:{"first-published-at":_vm.firstPublishedAt,"global-article-copy":_vm.globalArticleCopy,"last-updated-date":_vm.blok.legacyUpdatedDate,"published-at":_vm.publishedAt}})],1):_vm._e(),_vm._v(" "),_c('div',[_c('token-replace',{attrs:{"string":_vm.globalArticleCopy.numMinuteRead},scopedSlots:_vm._u([{key:"num",fn:function(){return [_vm._v("\n                            "+_vm._s(_vm.blok.readTimeMinutes)+"\n                        ")]},proxy:true}])})],1)])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }